import { IBatch, ISchoolLocation } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import Tag from '../tags/Tag';

interface IProps {
    batches: IBatch[];
    id: string;
    name: string;
    schoolLocations: ISchoolLocation[];
    value: number[];
}

function batchGroups(batches: IBatch[]): string[] {
    return Array.from(new Set(batches.map((b) => b.group)));
}

export default function MultiSLBatches({
    batches: allBatches,
    id: inputId,
    name: inputName,
    schoolLocations,
    value,
}: IProps): ReactElement {
    const [batches, setBatches] = useState<IBatch[]>([]);
    const [groups, setGroups] = useState<Set<string>>(new Set());
    const [schoolLocationIds, setSchoolLocationIds] = useState<Set<number>>(
        new Set(schoolLocations.map((sl) => sl.id)),
    );

    useEffect(() => {
        const selectedBatches = allBatches.filter((b) => value.includes(b.id));
        setBatches(selectedBatches);
        setGroups(new Set(selectedBatches.map((b) => b.group)));
        if (value.length > 0) {
            setSchoolLocationIds(
                new Set(selectedBatches.map((b) => b.school_location.id)),
            );
        }
    }, [value]);

    const filterBatches = (
        groups: Set<string>,
        schoolLocationIds: Set<number>,
    ): void => {
        const newBatches = allBatches.filter(
            (b) =>
                groups.has(b.group) &&
                schoolLocationIds.has(b.school_location.id),
        );
        setBatches(newBatches);
    };

    const onRemoveBatch = (id: number): void => {
        const newBatches = batches.filter((b) => b.id !== id);
        setBatches(newBatches);

        const newGroups = batchGroups(newBatches);
        if (Array.from(groups).some((g: string) => !newGroups.includes(g))) {
            setGroups(new Set(newGroups));
        }
    };

    const onToggleGroup = (group: string): void => {
        const newSet = new Set(groups);
        newSet.has(group) ? newSet.delete(group) : newSet.add(group);
        setGroups(newSet);
        filterBatches(newSet, schoolLocationIds);
    };

    const onToggleSchoolLocationId = (id: number): void => {
        const newSet = new Set(schoolLocationIds);
        newSet.has(id) ? newSet.delete(id) : newSet.add(id);
        setSchoolLocationIds(newSet);
        filterBatches(groups, newSet);
    };

    return (
        <div className="d-flex flex-column gap-5 ml-2">
            <div className="d-flex flex-column gap-2">
                <span className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                    Filter by school location
                </span>
                <div className="align-items-center d-flex flex-wrap gap-3 ml-1">
                    {schoolLocations.map(({ id, name }) => (
                        <span
                            className={`toggle-token${
                                schoolLocationIds.has(id) ? ' active' : ''
                            }`}
                            key={id}
                            onClick={(): void => onToggleSchoolLocationId(id)}
                            role="button"
                        >
                            {name}
                        </span>
                    ))}
                </div>
            </div>

            <div className="d-flex flex-column gap-2">
                <span className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                    Filter by cohort group
                </span>

                <div className="align-items-center d-flex flex-wrap gap-3 ml-1">
                    {batchGroups(
                        allBatches.filter((b) =>
                            schoolLocationIds.has(b.school_location.id),
                        ),
                    ).map((g) => (
                        <span
                            className={`toggle-token${
                                groups.has(g) ? ' active' : ''
                            }`}
                            key={g}
                            onClick={(): void => onToggleGroup(g)}
                            role="button"
                        >
                            C#{g}
                        </span>
                    ))}
                </div>
            </div>

            {batches.length > 0 ? (
                <div className="d-flex flex-column gap-2">
                    <strong style={{ whiteSpace: 'nowrap' }}>
                        Individual cohorts invited
                    </strong>
                    <div className="align-items-center d-flex flex-wrap gap-3 ml-1">
                        {batches.map((batch) => (
                            <span key={batch.id}>
                                <input
                                    id={inputId}
                                    name={inputName}
                                    type="hidden"
                                    value={batch.id}
                                />
                                <Tag
                                    fontSize={12}
                                    label={batch.full_name}
                                    onDeleteClick={(): void => {
                                        onRemoveBatch(batch.id);
                                    }}
                                    variant="info"
                                />
                            </span>
                        ))}
                    </div>
                </div>
            ) : (
                /* Rails require the field to be present to reset all the values if none is provided */
                <input id={inputId} name={inputName} type="hidden" />
            )}
        </div>
    );
}
